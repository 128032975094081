@function strip-units($value) {
	@return $value / ($value * 0 + 1);
}

@function rem-calc($px, $base-val: $base-font-size) {
	@if not unitless($px) {
		$px: strip-units($px);
	}
	@if not unitless($base-val) {
		$base-val: strip-units($base-val);
	}
	@return ($px / $base-val) * 1rem;
}

// rem-calc(320)