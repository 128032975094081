/* --------------------------------
   quantity counter
-------------------------------- */

.quantity-counter
{
	position: relative;
	width: 95px;

	.__q-input
	{
		display: block;
		float: none;
		width: 100%;
		height: 24px;
		background: padding-box none transparent;
		border: none;
		line-height: 1.2;
		font-size: 1.6rem;
		text-align: center;
		color: #494949;
		appearance: none;
		outline: none;
		padding: 0 30px;
		box-shadow: none;
		border-radius: 0;
	}

	.__btn
	{
		position: absolute;
		top: 0;
		bottom: 0;
		width: 24px;
		height: 24px;
		border: 2px solid #ddd;
		line-height: 20px;
		font-size: 2.8rem;
		text-align: center;
		color: #494949;
		cursor: pointer;
		opacity: 0.3;
		border-radius: 50%;
		user-select: none;
		@include transition(opacity 0.3s ease-in-out);

		&:hover { opacity: 1; }

		&:before,
		&:after
		{
			position: absolute;
			top: 50%;
			left: 50%;
			background-color: currentColor;
		}

		&--minus
		{
			left: 0;

			&:before
			{
				content: "";
				margin-top: -1px;
				margin-left: -5px;
				width: 10px;
				height: 2px;
			}
		}

		&--plus
		{
			right: 0;

			&:before
			{
				content: "";
				margin-top: -1px;
				margin-left: -5px;
				width: 10px;
				height: 2px;
			}

			&:after
			{
				content: "";
				margin-top: -5px;
				margin-left: -1px;
				width: 2px;
				height: 10px;
			}
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}