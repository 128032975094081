/* --------------------------------
   checkout
-------------------------------- */

.checkout
{
	&__form {}

	&__table
	{
		font-size: 1.6rem;
		color: #333;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;

		&:first-child { margin-top: 0; }

		table { min-width: 380px; }

		tbody
		{
			tr
			{
				border-bottom: 1px solid #e6e6e6;
			}
		}

		tr { }

		td
		{
			padding-top: 15px;
			padding-bottom: 15px;
			padding-left: 15px;

			&:first-child { padding-left: 0; }

			@include media-breakpoint-up(sm)
			{
				padding-left: 20px;
			}
		}

		.__image
		{
			position: relative;
			width: 80px;
			height: 80px;
			margin-right: 15px;

			@include media-breakpoint-up(lg)
			{
				margin-right: 25px;
			}

			a
			{
				display: block;
				width: 100%;
				height: 100%;
			}

			img
			{
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				max-width: 90%;
				max-height: 80%;
			}
		}

		.__name
		{
			display: inline-block;
			vertical-align: middle;
			line-height: 1.4;
			margin-bottom: 5px;
			font-family: $secondary-fontFamily;
			font-weight: 700;
			text-decoration: none;

			&:last-child { margin-bottom: 0; }

			&:not(:hover):not(:focus) { color: inherit; }
		}

		.__amount,
		.__total
		{
			font-weight: 700;
			white-space: nowrap;
		}
	}

	&__total
	{
		background-color: #f9f9f9;
		margin-top: 50px;
		line-height: 1.3;
		color: #8b8b8b;

		thead
		{
			tr { }

			td
			{
				padding-top: 35px;
				padding-bottom: 20px;
			}
		}

		tbody
		{
			font-size: 1.6rem;
			font-family: $secondary-fontFamily;
			text-align: right;

			tr
			{
				&:last-child
				{
					border-top: 1px solid #ebebeb;
					border-bottom: 1px solid #ebebeb;
				}
			}

			td
			{
				vertical-align: top;
				padding-top: 20px;
				padding-bottom: 20px;

				&:first-child
				{
					font-weight: 700;
					text-align: left;
					color: #333;
				}
			}
		}

		tfoot
		{
			background-color: #e8e8e8;

			tr
			{
				&:last-child
				{
					td
					{
						padding-top: 30px;
						padding-bottom: 40px;
					}
				}
			}

			td
			{
				padding-top: 25px;
				padding-bottom: 25px;
			}
		}

		tr { }

		td
		{
			padding-left: 15px;
			padding-right: 15px;

			&:first-child { padding-left: 30px; }
			&:last-child { padding-right: 30px; }
		}

		.__note
		{
			background-color: #bce5dd;
			color: #57887e;

			.__ttl
			{
				font-size: 1.2rem;
				font-weight: 700;
				font-family: $secondary-fontFamily;
				text-transform: uppercase;
				color: #3a5d56;
			}
		}

		.custom-btn { margin-top: 0; }
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}