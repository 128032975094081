/* --------------------------------
   product-promo
-------------------------------- */

$gutter: 30px;

.product-promo
{
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	.__item
	{
		position: relative;

		&:hover
		{
			.__image
			{
				img { transform: scale(1.2) translateZ(0); }
			}
		}
	}

	.__image
	{
		position: relative;
		margin: auto;
		overflow: hidden;

		img
		{
			display: block;
			width: 100%;
			height: 100%;
			@include object-fit(cover, 50% 50%);
			@include transition(
				transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
				opacity 200ms
			);
		}
	}

	.__content
	{
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 15px;
		
	}

	.__title { color: inherit; }

	.__link
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.product-promo--style-1
{
	.__inner { margin-bottom: -$gutter; }

	.__item { margin-bottom: $gutter; }

	.__content
	{
		align-items: center;
		justify-content: center;
		color: $white-color;
		@include transition(background-color 0.3s ease-in-out);
	}
}

.product-promo--style-2
{
	max-width: 1380px;
	margin-top: -120px;

	.__item
	{
		width: 100%;
		min-height: 320px;

		&[data-y="2"] .__image { height: 640px; }
	}

	.__image
	{
		width: 100%;
		height: 320px;

		&:after
		{
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 35%;
			min-height: 200px;
			background: -moz-linear-gradient(90deg, rgba(0,0,0,0.75) 0%, rgba(255,255,255,0) 100%);
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(0,0,0,0.75)));
			background: -webkit-linear-gradient(90deg, rgba(0,0,0,0.75) 0%, rgba(255,255,255,0) 100%);
			background: -o-linear-gradient(90deg, rgba(0,0,0,0.75) 0%, rgba(255,255,255,0) 100%);
			background: -ms-linear-gradient(90deg, rgba(0,0,0,0.75) 0%, rgba(255,255,255,0) 100%);
			background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(255,255,255,0) 100%);
		}

		+ .__content
		{
			align-items: flex-end;
			justify-content: flex-start;
			color: $white;
		}
	}

	.__content
	{
		align-items: center;
		justify-content: center;
		color: $primary-color;
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	.product-promo--style-1
	{
		margin-top: -(100 + 160) + px;

		.__item
		{
			&--first
			{
				margin-top: 10%;
				margin-right: 10%;
				z-index: 1;

				.__content
				{
					&:before
					{
						pointer-events: none;
						content: "";
						position: absolute;
						top: -15%;
						right: -10%;
						width: 65%;
						height: 105%;
						border: 10px solid $primary-color;
						z-index: -1;
					}
				}
			}

			&--second { margin-left: -35%; }
		}
	}

	.product-promo--style-2
	{
		margin-top: -(100 + 90) + px;
	}
}

@include media-breakpoint-up(lg)
{
	.product-promo--style-1
	{
		margin-top: -(140 + 160) + px;

		.__item
		{
			&--second { margin-left: -45%; }
		}
	}

	.product-promo--style-2
	{
		margin-top: -(140 + 110) + px;
	}
}

@include media-breakpoint-up(xl)
{
	
}