/* --------------------------------
   team
-------------------------------- */

$gutter: 30px;

.team
{
	.__inner { margin-bottom: -$gutter; }

	.__item
	{
		position: relative;
		width: 100%;
		margin-bottom: $gutter;

		.__image
		{
			position: relative;
			width: 100%;
			height: 0;
			padding-top: percentage(330/370);
			margin: auto;
			overflow: hidden;

			img
			{
				@extend %block-absolute--full;
				@include object-fit(cover, 50% 50%);
				@include transition(
					transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
					opacity 200ms
				);
			}
		}

		.__content
		{
			position: relative;
			margin: -40px 20px 0;
			padding: 30px 20px 20px;
			background-color: $white-color;
			line-height: 1;
			font-size: rem-calc(12px);
			text-align: center;
			text-transform: uppercase;
			color: #ddc051;
			@include transition(transform 300ms ease-in-out);
		}

		.__title
		{
			font-weight: 700;
			text-transform: none;
			margin-bottom: 10px;
		}

		&:hover
		{
			.__image img { transform: scale(1.2) translateZ(0); }

			.__content
			{
				.desktop & { transform: translateY(-40px); }
			}
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}