/* --------------------------------
   partners list
-------------------------------- */

.partners-list
{
	.slick-dots
	{
		position: relative;
		margin-top: 20px;
		text-align: center;
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}