/* --------------------------------
   counters
-------------------------------- */

$gutter: 30px;

.counter
{
	.__inner { margin-bottom: -$gutter; }

	.__item
	{
		width: 100%;
		margin-bottom: $gutter;

		p
		{
			margin-top: 10px;
			margin-bottom: 10px;
		}

		.__ico
		{
			display: block;
			width: 85px;
			margin-right: 15px;

			img {  }
		}

		.__count
		{
			line-height: 1;
			font-size: rem-calc(50px);
			font-weight: 900;
			color: #333;
		}

		.__title
		{
			line-height: 1.2;
			font-size: rem-calc(16px);
			font-weight: 700;
			font-family: $secondary-fontFamily;
			color: #555;
		}
	}
}

[class$="base-bg"],
[class*="base-bg"]
{
	.counter
	{
		.__item
		{
			.__title
			{
				color: #9a8841;
			}
		}
	}
}

[class$="gray-bg"],
[class*="gray-bg"]
{
	.counter
	{
		.__item
		{
			.__title
			{
				color: #858585;
			}
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}